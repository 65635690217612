<template>
    <div class="hotel-import">
        <a class="button button--link button--text-medium" href="#" @click="showModal = true">
            <img src="@/assets/icons/prime_file-excel.svg" width="20" height="20" alt="">
            Import Excel
        </a>

        <div class="hotel-import__modal" v-if="showModal">
            <div class="hotel-import__modal-content">
                <h1 class="hotel-import__modal-title">Setup My Hotel From Excel File</h1>

                <div class="hotel-import__modal-file">
                    <label for="excel-file"
                        :class="[!file ? 'hotel-import--border-default' : 'hotel-import--border-green', 'hotel-import__file']">
                        <span v-if="!placeHolderName">Click To Select Your Hotel Setup File</span>
                        <span v-else>{{ placeHolderName }}</span>

                        <input type="file" id="excel-file" @change="setFile">
                    </label>

                    <a 
                        href="./files/hotel_import_template.xlsx" 
                        class="hotel-import__file-template"
                        v-if="!file" download
                    >
                        <img src="@/assets/icons/vuesax/linear/receive-square-blue.svg" alt="">
                        Download Template
                    </a>

                    <div class="hotel-import__errors" v-if="errors.hasErrors">
                        <span>
                            {{ errors.message }}
                        </span>
                    </div>
                </div>

                <div class="hotel-import__buttons">
                    <button :class="[file ? 'button--success' : 'button--disabled', 'button']" :disabled="!file"
                        @click="loadHotel" v-if="!showBtnLoader">
                        Load My Hotel
                    </button>

                    <ButtonTextLoader loader-text="Importing Hotel"
                        button-class="button button--default hotel-import__button-loader" :is-loading="showBtnLoader"
                        v-if="showBtnLoader" />

                    <button class="button button--primary" @click="closeModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue"
import { hotelStatusesServices } from "../Services/HotelStatusService.js"
import { useHotelStatusStore } from '@/stores/HotelStatusStore.js'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useClientStore } from '@/stores/ClientStore.js'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'

const file = ref(null)
const placeHolderName = ref(null)
const { importHotelExcelService, errors, resetErrors } = hotelStatusesServices()
const showModal = ref(false)
const { sweetSuccess } = sweetAlerts()
const { getHotelStatus } = useHotelStatusStore()
const showBtnLoader = ref(false)
const { client } = useClientStore()
const { joinPanicAlertEvent } = usePanicAlertStore()

function setFile(event) {
    const excelFile = event.target.files[0]

    file.value = { hotelFile: excelFile }
    placeHolderName.value = excelFile.name
}

async function loadHotel() {
    showBtnLoader.value = true

    // * Request hotel import service
    const response = await importHotelExcelService(file.value)

    // * Return error in console
    if (response.hasErrors) {
        showBtnLoader.value = false
        return console.error(`Error importing hotel: ${response.message}`)
    }

    // * Refresh hotel setup statuses
    await getHotelStatus()

    // * Set hotel values in clientStore
    client.hotelId = response.data.data.hotelId
    client.hotel = response.data.data.hotel
    client.hotelAddress = response.data.data.address

    // * Close hotel import modal
    closeModal()

    // * Join into panic alert event
    joinPanicAlertEvent()

    // * Show sweet alert success
    sweetSuccess(response.data.message)
}

function closeModal() {
    showModal.value = false
    file.value = null
    placeHolderName.value = null
    showBtnLoader.value = false

    resetErrors()
}
</script>