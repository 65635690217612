<template>
    <div class="hotel-setup">
        <div class="hotel-setup__content hotel-setup--default-content">
            <img src="@/assets/images/prosafe-new-logo.svg" class="hotel-setup__logo" alt="">

            <div class="hotel-setup__info">
                <StepsGuide :hotel-status="hotel" />
            </div>

            <div class="hotel-setup__options">
                <div class="hotel-setup__option-left">
                    <router-link class="button button--link button--text-medium" to="/" v-if="hotel.isCompleted">
                        <img src="@/assets/icons/ic_twotone-arrow-back-ios-new.svg" width="13" height="13" alt="">
                        Home
                    </router-link>
                    <div class="hotel-setup__complete-steps" v-else>
                        <img src="@/assets/icons/red-point.svg" width="7" height="7" alt="">
                        Complete all steps
                    </div>
                </div>
                <div class="hotel-setup__option-right">
                    <HotelImport v-if="!hotel.isCompleted" />
                </div>
            </div>

            <HotelStatusesComponent :hotel-status="hotel" />
        </div>
    </div>
</template>

<script setup>
import HotelStatusesComponent from '../Components/HotelStatusesComponent.vue'
import StepsGuide from '../Components/StepsGuide.vue'
import { useHotelStatusStore } from '@/stores/HotelStatusStore.js'
import HotelImport from '../Components/HotelImport.vue'

const { hotel } = useHotelStatusStore()
</script>