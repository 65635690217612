import { httpRequests } from '../../../helpers/HttpRequests'

export function hotelStatusesServices() {
    const { getRequest, postFormRequest, httpErrors, resetErrors } = httpRequests()
    const errors = httpErrors;

    function hotelStatusService() {
        return getRequest('/web/hotel/status')
    }

    function importHotelExcelService(excelFile) {
        return postFormRequest('/web/hotel_import', excelFile)
    }

    return { hotelStatusService, importHotelExcelService, errors, resetErrors }
}