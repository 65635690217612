<template>
    <div class="hotel-setup__card-content">
        <router-link to="/hotel_setup/info" class="hotel-setup__status-info">
            <div class="hotel-setup__status-text">
                <img src="@/assets/icons/ri_hotel-line-blue.svg" width="20" height="20" alt="">
                <div class="hotel-setup__status-description">
                    <div class="hotel-setup__status">
                        Hotel Information
                        <img src="@/assets/icons/red-point.svg" width="7" height="7" alt=""
                            v-if="!hotelStatus.hotelInformation">
                    </div>
                    <span>Add all pertinent hotel information</span>
                </div>
            </div>

            <img src="@/assets/icons/ph_seal-check-fill.svg" width="20" height="20" alt=""
                v-if="hotelStatus.hotelInformation">
            <img src="@/assets/icons/vuesax/linear/close-circle.svg" width="20" height="20" alt="" v-else>
        </router-link>

        <router-link to="/hotel_setup/floors" :class="disableLink(hotelStatus.hotelInformation)">
            <div class="hotel-setup__status-text">
                <img src="@/assets/icons/material-symbols_floor.svg" width="20" height="20" alt="">

                <div :class="disableText(hotelStatus.hotelInformation)">
                    <div class="hotel-setup__status">
                        Hotel Floors
                        <img src="@/assets/icons/red-point.svg" width="7" height="7" alt="" v-if="!hotelStatus.hotelFloors">
                    </div>

                    <span>Manage and configure hotel floors</span>
                </div>
            </div>

            <img src="@/assets/icons/ph_seal-check-fill.svg" width="20" height="20" alt="" v-if="hotelStatus.hotelFloors">
            <img src="@/assets/icons/vuesax/linear/close-circle.svg" width="20" height="20" alt="" v-else>
        </router-link>

        <router-link to="/hotel_setup/rooms" :class="disableLink(hotelStatus.hotelFloors)">
            <div class="hotel-setup__status-text">
                <img src="@/assets/icons/mdi_guest-room-outline-blue.svg" width="20" height="20" alt="">
                <div :class="disableText(hotelStatus.hotelFloors)">
                    <div class="hotel-setup__status">
                        Hotel Rooms
                        <img src="@/assets/icons/red-point.svg" width="7" height="7" alt="" v-if="!hotelStatus.hotelRooms">
                    </div>
                    <span>Manage and configure hotel rooms.</span>
                </div>
            </div>

            <img src="@/assets/icons/ph_seal-check-fill.svg" width="20" height="20" alt="" v-if="hotelStatus.hotelRooms">
            <img src="@/assets/icons/vuesax/linear/close-circle.svg" width="20" height="20" alt="" v-else>
        </router-link>

        <router-link to="/hotel_setup/areas" :class="disableLink(hotelStatus.hotelFloors)">
            <div class="hotel-setup__status-text">
                <img src="@/assets/icons/majesticons_map-marker-area-line.svg" width="20" height="20" alt="">
                <div :class="disableText(hotelStatus.hotelFloors)">
                    <div class="hotel-setup__status">
                        Hotel Areas
                        <img src="@/assets/icons/red-point.svg" width="7" height="7" alt="" v-if="!hotelStatus.hotelAreas">
                    </div>
                    <span>Manage and configure hotel areas.</span>
                </div>
            </div>

            <img src="@/assets/icons/ph_seal-check-fill.svg" width="20" height="20" alt="" v-if="hotelStatus.hotelAreas">
            <img src="@/assets/icons/vuesax/linear/close-circle.svg" width="20" height="20" alt="" v-else>
        </router-link>
    </div>
</template>

<script setup>
import { useHotelStatusStore } from "@/stores/HotelStatusStore.js"
import { onMounted } from 'vue'

const { getHotelStatus } = useHotelStatusStore()

defineProps(['hotelStatus'])

onMounted(async () => {
    // * Set hotel setup data
    await getHotelStatus()
})

const disableLink = (status) => {
    return status ? 'hotel-setup__status-info' : 'hotel-setup__status-info hotel-setup--disable-navigation'
}
const disableText = (status) => {
    return status ? 'hotel-setup__status-description' : 'hotel-setup__status-description hotel-setup--disable-text'
}
</script>